import { default as React } from "react"
import { graphql, Link } from "gatsby"
import { CgArrowLongRight } from 'react-icons/cg'
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import Sidebar from "~/components/articles/sidebar"
import RelatedArticles from "~/components/articles/related"

const ArticlePage = ({ data: { article } }) => {
  const related = article.categories?.nodes?.map(({ posts: { nodes }}) => nodes.filter(({databaseId}) => databaseId !== article.databaseId)).flat()
  return (
    <Layout>
      <Seo title={article.title} />
      <section
        style={{
          backgroundImage: `url('${article.posts?.heroImage?.localFile?.childImageSharp?.fluid?.base64}')`,
          borderBottomRightRadius: '50% 10%'
        }}
        className="relative pt-36 bg-primary bg-cover bg-center overflow-hidden text-white">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full px-8 mb-6">
              <h1
                className="text-7xl text-white mb-2">{article.title}
              </h1>
              <em>{article.date}</em>
            </div>
          </div>
          <div className="flex flex-wrap -mx-8">
            <div className="flex flex-col w-full px-8">
              <ul
                className="flex items-center list-none text-white mb-12">
                <li
                  className="mr-3">
                  <Link
                    to={`/resource-center`}>
                    Resource Center
                  </Link>
                </li>
                <li
                  className="mr-3">
                  <CgArrowLongRight />
                </li>
                <li
                  className="mr-3">
                  <span>{article.title}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-28 py-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full md:w-2/3 flex flex-col px-8">
              <div
                className="leading-loose"
                dangerouslySetInnerHTML={{
                  __html: article.content
                }} />
              {!!article.tags.nodes.length && (
                <ul
                  className="flex flex-wrap items-center list-none mt-12 mb-6 p-0">
                  { article.tags.nodes.map(({ name, termTaxonomyId }) => (
                    <li
                      className="px-6 py-3 mr-4 bg-secondary text-white rounded-md"
                      key={termTaxonomyId}>
                      {name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="w-full md:w-1/3 px-8">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
      { !!related.length && (
        <RelatedArticles
          related={related} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($databaseId: Int!){
    article: wpPost(databaseId: { eq: $databaseId }){
      databaseId
      title
      content
      date(formatString: "dddd, MMM D, YYYY")
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      categories {
        nodes {
          databaseId
          slug
          name
          posts{
            nodes{
              databaseId
              title
              slug
              excerpt
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100, aspectRatio: 1)
                    }
                  }
                }
              }
            }
          }
        }
      }
      tags{
        nodes{
          termTaxonomyId
          name
        }
      }
      posts{
        heroImage{
          localFile{
            childImageSharp{
              fluid(toFormatBase64: WEBP, base64Width: 2000){
                base64
              }
            }
          }
        }
      } 
    }
  }
`

export default ArticlePage