import { default as React } from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {CgArrowLongRight} from 'react-icons/cg'

const ArticleSlide = ({ article: { slug = '', title = '', excerpt = '', featuredImage: { node: { localFile } } } }) => {
  return (
    <div
      className="h-full flex flex-wrap border-t border-r border-l border-gray-100 rounded-md overflow-hidden w-full shadow-xl hover:shadow-2xl">
      <div
        className="w-full md:w-1/3">
        <GatsbyImage className={`h-full`} image={getImage(localFile)} alt={title} />
      </div>
      <div
        className="w-full md:w-2/3 flex flex-col p-12">
        <h3 className="text-primary">{title}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: excerpt
          }} />
        <Link
          className="flex items-center mt-auto text-secondary underline"
          to={`/articles/${slug}`}>
          <span className="mr-2">Continue Reading</span>
          <CgArrowLongRight />
        </Link>
      </div>
    </div>
  )
}

export default ArticleSlide