import { default as React } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import DotsIcon from "~/images/dots.inline.svg"

const Sidebar = () => {
  const { allWpCategory: { nodes: categories } } = useStaticQuery(graphql`
    {
      allWpCategory(filter: { name: { ne: "Uncategorized"}}){
        nodes{
          databaseId
          slug
          name
        }
      }
    }
  `)

  return (
    <div
      className="relative h-full">
      <DotsIcon className="absolute w-32 h-32 top-0 right-0 -mt-8 -mr-8" />
      <div
        className="sticky top-40 flex flex-col p-12 bg-gray-100 z-10">
        <h3 className="text-primary text-2xl">Explore Categories</h3>
        <div className="w-20 h-0.5 my-6 bg-gray-300" />
        <ul
          className="list-none m-0 p-0">
          {categories.map(({ databaseId, slug, name }) => (
            <li
              key={databaseId}
              className="flex items-center mb-4">
              <div
                className="w-2 h-2 flex-shrink rounded-full bg-secondary mr-4" />
              <Link
                to={`/category/${slug}`}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Sidebar