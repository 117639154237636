/** @jsx jsx */
import {jsx} from "theme-ui"
import Swiper from "~/components/swiper"
import Slide from "~/components/articles/related/slide"

const RelatedArticles = ({ related }) => {
  return (
    <section className="pb-24">
      <div className="container mx-auto px-8 mb-12">
        <div className="flex flex-wrap -mx-8">
          <div className="w-full px-8">
            <h2 className="text-5xl text-primary">You May Like</h2>
          </div>
        </div>
      </div>
      <div
        sx={{
          '.swiper-slide': {
            width: ['100%', '50%']
          }
        }}
        className="w-full">
        <Swiper
          centeredSlides={true}
          slidesPerView={`auto`}
          showNavigation={false}
          spaceBetween={45}
          slides={related.map(article => <Slide key={article.databaseId} article={article} />)} />
      </div>
    </section>
  )
}

export default RelatedArticles